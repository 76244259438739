import React, { FunctionComponent, ReactElement } from "react";
import { Field } from "formik";
import styles from "./TextField.module.css";

interface Props {
  type: string;
  name: string;
  placeHolder?: string;
  className?: string;
  error?: boolean;
  value?: any;
  disabled?: boolean;
  onChange?: any;
}

const TextField: FunctionComponent<Props> = ({
  type,
  name,
  placeHolder,
  className,
  error,
  value,
  disabled,
  onChange,
}): ReactElement => {
  return (
    <>
      <Field
        className={`${styles.textInput}  ${className && className} ${
          error && styles.error
        }`}
        type={type}
        name={name}
        placeholder={placeHolder}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </>
  );
};

export default TextField;
