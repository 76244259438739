import React, { useState, createContext, useContext } from "react";
import { LOCALSTORAGE_KEYS } from "../constants";

interface AppContextInterface {
  auth: any;
  setAuth: React.Dispatch<any>;
}
const authContext = createContext<AppContextInterface>(
  {} as AppContextInterface
);

export function ProvideAuth(props: any) {
  const { children } = props;
  const [auth, setAuth] = useState(() => {
    const authToken = window.localStorage.getItem(LOCALSTORAGE_KEYS.authToken);
    if (authToken) {
      return true;
    }
    return false;
  });

  return (
    <authContext.Provider value={{ auth, setAuth }}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}
