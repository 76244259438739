import React from "react";
import { ProvideAuth } from "./navigation/ProvideAuth";
import RouterConfig from "./navigation/RouterConfig";

function App() {
  return (
    <div className="App">
      <ProvideAuth>
        <RouterConfig />
      </ProvideAuth>
    </div>
  );
}

export default App;
