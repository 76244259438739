import filterImage from "./filter.png";
import christianImage from "./christian.png";
import heartImage from "./heart.png";
import HomeImage from "./Home.png";
import islamImage from "./islam.png";
import MembersImage from "./members.png";
import mindfulImage from "./mindful.png";
import Smile1Image from "./Smile-1.png";
import Smile2Image from "./Smile-2.png";
import Smile3Image from "./Smile-3.png";
import Smile4Image from "./Smile-4.png";
import Smile5Image from "./Smile-5.png";

const images = {
  filterImage,
  christianImage,
  heartImage,
  HomeImage,
  islamImage,
  MembersImage,
  mindfulImage,
  Smile1Image,
  Smile2Image,
  Smile3Image,
  Smile4Image,
  Smile5Image,
};
export default images;
