import { axiosConfig } from "../../axiosConfig/axiosConfig";
import { CreateUser } from "./types";
export const Createuser = async (userDet: Array<CreateUser>) => {
  try {
    const url = `/auth/create-user`;
    const response = await axiosConfig(url, "post", userDet, "");
    return response;
  } catch (err) {
    return err;
  }
};
