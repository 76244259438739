import axios from "axios";
import * as Yup from "yup";
import { getHost } from "../../axiosConfig/axiosConfig";

type LoginSuccessData = {
  sessionId: string;
  accessToken: string;
  refreshToken: string;
};
export interface LoginResponse {
  status: "success" | "error";
  message: string;
  data?: LoginSuccessData;
}

export interface LoginFormData {
  userName: string;
  password: string;
}
export const initialValues: LoginFormData = {
  userName: "",
  password: "",
};

export const LoginSchema = Yup.object().shape({
  userName: Yup.string().required("This Field is Required"),
  password: Yup.string().required("This Field is required"),
});

const matchRegistrationErrors = (errorCode: string): string => {
  if (errorCode === "Invalid Credentials") {
    return "Invalid UserName/Password";
  } else {
    return "";
  }
};

export const Login = async (values: LoginFormData): Promise<LoginResponse> => {
  try {
    const url = getHost() + "/api/auth/admin-login";
    const payload = {
      username: values.userName,
      password: values.password,
    };

    const { data } = await axios.post(url, payload);
    return {
      status: "success",
      message: "",
      data: data?.data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message:
        matchRegistrationErrors(err?.response?.data?.error) ||
        "Something Went Wrong Please try again",
    };
  }
};
