import React, { FunctionComponent, ReactElement } from "react";
import styles from "./ErrorMessage.module.css";
import { ErrorMessage as FormikErrorMessage } from "formik";

interface Props {
  name: string;
}

const ErrorMessage: FunctionComponent<Props> = ({ name }): ReactElement => {
  return (
    <>
      <FormikErrorMessage
        className={styles.errorMessage}
        name={name}
        component="div"
      />
    </>
  );
};

export default ErrorMessage;
