import React, { ChangeEventHandler, FC } from "react";
import { DropDownAttributes } from "../../utils";
import styles from "./SelectDropDown.module.css";

interface Props {
  title?: string;
  options?: Array<DropDownAttributes> | undefined;
  onChange?: any;
  titleExistOnChange: boolean;
}

const SelectDropDown: FC<Props> = ({
  title,
  options,
  titleExistOnChange,
  onChange,
}: Props) => {
  return (
    <div className={styles.dropdown}>
      <select className={styles.selectDropDown} onChange={onChange}>
        {titleExistOnChange ? (
          <option value="">{title}</option>
        ) : (
          <option value="" hidden>
            {title}
          </option>
        )}

        {options &&
          options.map((opt: DropDownAttributes, index: number) => (
            <option key={index} value={opt.id}>
              {opt.description}
            </option>
          ))}
      </select>
    </div>
  );
};

export default SelectDropDown;
