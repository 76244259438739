import { LOCALSTORAGE_KEYS } from "../constants";
import { axiosConfig, getHost } from "./axiosConfig";

export interface RefreshTokenResponse {
  status: "success" | "error";
  message: string;
  data?: any;
}

export const ValidateRefreshToken = async (): Promise<RefreshTokenResponse> => {
  try {
    const refreshToken = localStorage.getItem(LOCALSTORAGE_KEYS.refreshToken);
    const sessionId = localStorage.getItem(LOCALSTORAGE_KEYS.sessionId);
    const payload = {
      refreshToken: refreshToken,
      isAdmin: true,
      sessionId: sessionId,
    };

    const url = `${getHost()}/api/auth/refresh-token`;
    const { data } = await axiosConfig(url, "post", payload, "");
    return {
      status: "success",
      message: "",
      data: data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something Went Wrong",
    };
  }
};
