import { FC, ReactNode, useState, useEffect, ChangeEvent } from "react";
import {
  Popover,
  Typography,
  Backdrop,
  TextField,
  Fab,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { Cancel, Save } from "@mui/icons-material";
import { BeliefsAttributes } from "../../utils";
import { Createuser } from "./utils";
interface Props {
  open: boolean;
  title?: string;
  beliefs: Array<BeliefsAttributes>;
  children?: ReactNode;
  anchorEl: HTMLButtonElement | null;
  onAccept(): void;
  onReject(): void;
  errorCreatingUser(string?): void;
  [propName: string]: any;
}

const CreateUserDialog: FC<Props> = ({
  open,
  title,
  children,
  anchorEl,
  beliefs,
  onAccept,
  onReject,
  errorCreatingUser,
  ...rest
}: Props) => {
  const [belief, setBelief] = useState<string | undefined>("");
  const [isAdmin, setAdmin] = useState<boolean | undefined>(false);
  const [username, setUsername] = useState<string | undefined>("");
  const [password, setPassword] = useState<string | undefined>("");
  const changeAdmin = (event: ChangeEvent) => {
    setAdmin(!isAdmin);
  };
  const changeUsername = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
  };
  const changePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };
  const changeBelief = (event: ChangeEvent<HTMLInputElement>) => {
    setBelief(event.target.value);
  };
  const resetForm = () => {
    setUsername("");
    setPassword("");
    setAdmin(false);
    if (beliefs.length > 0) setBelief(beliefs[0].id?.toString());
  };
  const handleDialogClose = () => {
    resetForm();
    onReject();
  };
  const handleSuccessDialog = async () => {
    try {
      if (validateForm()) {
        let res: any;
        if (isAdmin) res = await Createuser([{ username, isAdmin, password }]);
        else res = await Createuser([{ username, belief }]);
        if (res.statusCode != 200) throw "Error creating user";
        onAccept();
        handleDialogClose();
      }
    } catch (err: any) {
      errorCreatingUser();
      handleDialogClose();
    }
  };
  const validateForm = () => {
    if (!username) {
      errorCreatingUser("Username is mandatory");
      return false;
    }
    if (isAdmin && !password) {
      errorCreatingUser("Password is mandatory for admin user");
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (beliefs.length > 0) setBelief(beliefs[0].id?.toString());
  }, [beliefs]);
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      //onClick={() => {}}
    >
      <Popover
        open={open}
        anchorEl={anchorEl}
        //onClose={() => {}}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FormControl
          style={{ padding: "10px 24px 24px 24px", textAlign: "center" }}
        >
          <FormLabel
            component="h1"
            style={{
              color: "black",
              fontSize: "1.5rem",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            Create Member
          </FormLabel>
          <FormGroup aria-label="position">
            <FormControlLabel
              value={isAdmin}
              style={{
                paddingBottom: "10px",
              }}
              control={
                <Checkbox
                  checked={isAdmin}
                  name="Admin"
                  onChange={changeAdmin}
                />
              }
              label="Administrator"
              labelPlacement="end"
            />
            {!isAdmin ? (
              <FormControlLabel
                value={belief}
                style={{
                  paddingBottom: "10px",
                  width: "100%",
                }}
                control={
                  <TextField
                    id="outlined-select-belief"
                    select
                    label="Belief"
                    style={{ width: "100%", textAlign: "left" }}
                    helperText=""
                    onChange={changeBelief}
                  >
                    {beliefs.length > 0
                      ? beliefs.map((option, idx) => (
                          <MenuItem key={idx} value={option.id}>
                            {option?.name}
                          </MenuItem>
                        ))
                      : ""}
                  </TextField>
                }
                label=""
                labelPlacement="end"
              />
            ) : (
              ""
            )}
            <FormControlLabel
              value={username}
              style={{
                paddingBottom: "10px",
                width: "100%",
              }}
              control={
                <TextField
                  id="outlined-basic"
                  label="Username"
                  style={{ width: "100%" }}
                  variant="outlined"
                  onChange={changeUsername}
                />
              }
              label=""
              labelPlacement="end"
            />
            {isAdmin ? (
              <FormControlLabel
                value={password}
                style={{
                  paddingBottom: "10px",
                  width: "100%",
                }}
                control={
                  <TextField
                    id="outlined-basic"
                    label="Password"
                    style={{ width: "100%" }}
                    variant="outlined"
                    type="password"
                    onChange={changePassword}
                  />
                }
                label=""
                labelPlacement="end"
              />
            ) : (
              ""
            )}
          </FormGroup>
          <div style={{ textAlign: "center" }}>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
              onClick={handleSuccessDialog}
              style={{
                marginRight: "12px",
              }}
            >
              <Save sx={{ mr: 1 }} />
              Save
            </Fab>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="close"
              onClick={handleDialogClose}
            >
              <Cancel sx={{ mr: 1 }} />
              Cancel
            </Fab>
          </div>
        </FormControl>
      </Popover>
    </Backdrop>
  );
};

export default CreateUserDialog;
