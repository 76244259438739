import React, { FunctionComponent, ReactElement } from "react";
import TextField from "../../components/TextField";
import ErrorMessage from "../../components/ErrorMessage";

interface Props {
  type: string;
  name: string;
  placeHolder?: string;
  className?: string;
  error?: boolean;
  focus?: boolean;
  value?: any;
  disabled?: boolean;
  onChange?: any;
}

const InputTextField: FunctionComponent<Props> = ({
  type,
  name,
  placeHolder,
  className,
  error,
  value,
  disabled,
  onChange,
}): ReactElement => {
  return (
    <div className={`${className && className}`}>
      <TextField
        type={type}
        name={name}
        placeHolder={placeHolder}
        error={error}
        value={value && value}
        disabled={disabled}
        onChange={onChange}
      />
      <ErrorMessage name={name} />
    </div>
  );
};

export default InputTextField;
