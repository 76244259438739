import React, { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import styles from "./DashBoardLayout.module.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { getUserCount, logoutUser } from "./utils";
import { useAuth } from "../../navigation/ProvideAuth";
import { HOME, LOGIN } from "../../navigation/constant";
import { images } from "../../assets/images/index";
import { LOCALSTORAGE_KEYS } from "../../constants";
interface Props {
  sidebar: boolean;
  exportButton?: ReactNode;
  children?: ReactNode;
  updateCount?: boolean;
}
const DashBoardLayout: FC<Props> = ({
  sidebar,
  children,
  exportButton,
  updateCount,
}: Props): ReactElement => {
  const [userCount, setUserCount] = useState<number | undefined>();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const Logout = async () => {
    try {
      const response = await logoutUser();
      if (response.status === "success") {
        setAuth(null);
      }
      Object.keys(LOCALSTORAGE_KEYS).forEach((key) => {
        localStorage.removeItem(key);
      });
      navigate(LOGIN, { replace: true });
    } catch (err) {
      return err;
    }
  };
  const GetUserCount = async () => {
    const response = await getUserCount();
    if (response.status === "success") {
      setUserCount(response.data.totalUsers);
    }
  };
  useEffect(() => {
    GetUserCount();
  }, [userCount, updateCount]);
  return (
    <>
      <div className={styles.homepage}>
        <div className={styles.header}>
          <div className={styles.logoContainer} onClick={() => navigate(HOME)}>
            <img src={images.heartImage} alt="Logo" />
          </div>
          <div className={styles.exportContainer}>
            <div className={styles.buttonContainer}>{exportButton}</div>
            <div>
              <span
                className={styles.spanStyle}
                style={{ color: "#000000" }}
                onClick={Logout}
                data-testid="SignOut Button"
              >
                Sign Out
              </span>
            </div>
          </div>
        </div>
        {sidebar ? (
          <Grid container>
            <Grid item md={2}>
              <div className={styles.sidebar} data-testid="sidebar">
                <ul>
                  {" "}
                  <NavLink
                    to="/"
                    className={`${styles.navLink} ${
                      location.pathname === "/" && styles.activeLink
                    }`}
                  >
                    <div className={styles.navLinkContainer}>
                      <img
                        src={images.HomeImage}
                        style={{ height: "28px", width: "28px" }}
                      />
                      <h5>Home</h5>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/Members"
                    className={`${styles.navLink} ${
                      location.pathname === "/Members" && styles.activeLink
                    }`}
                  >
                    <div className={styles.navLinkContainer}>
                      <img
                        src={images.MembersImage}
                        style={{ height: "28px", width: "28px" }}
                      />

                      <h5>
                        Members
                        <span data-testid="userCount">
                          {userCount && `(${userCount})`}
                        </span>
                      </h5>
                    </div>
                  </NavLink>
                </ul>
              </div>
            </Grid>
            {children}
          </Grid>
        ) : null}
      </div>
    </>
  );
};
export default DashBoardLayout;
