import { axiosConfig } from "../../axiosConfig/axiosConfig";
export const options = [
  {
    id: "1",
    description: "Week 1",
  },
  {
    id: "2",
    description: "Week 2",
  },
  {
    id: "3",
    description: "Week 3",
  },
  {
    id: "4",
    description: "Week 4",
  },
  {
    id: "5",
    description: "Week 5",
  },
  {
    id: "6",
    description: "Week 6",
  },
  {
    id: "7",
    description: "Week 7",
  },
  {
    id: "8",
    description: "Week 8",
  },
];
export interface IInfo {
  info: [];
  totalUsers: string;
  name: string;
}
export interface IMeditationData {
  mindfulness: IInfo;
  islam: IInfo;
  christian: IInfo;
}
export interface IResponse {
  status: "success" | "error";
  message?: string;
  data?: IMeditationData | any;
}
export const GetAllWeeks = async () => {
  try {
    const url = "/weeks";

    const { data }: any = await axiosConfig(url, "get", {}, "");
    return data;
  } catch (err: any) {
    return err;
  }
};
export const GetAllMeditationData = async (): Promise<IResponse> => {
  try {
    const url = "/stats/weekly-info";
    const { data } = await axiosConfig(url, "get", {}, "");
    return {
      status: "success",
      message: "",
      data: data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something Went Wrong, Please try again",
    };
  }
};
export const GetMeditationDataByWeek = async (weekNo): Promise<IResponse> => {
  try {
    const url = `/stats/single-week-info?week=${weekNo}`;
    const { data }: any = await axiosConfig(url, "get", {}, "");

    return {
      status: "success",
      message: "",
      data: data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something Went Wrong, Please try again",
    };
  }
};

export const ExportAllWeekData = async () => {
  try {
    const url = "/export/weekly-info";
    const response: any = axiosConfig(url, "get", {}, "arraybuffer");
    return response;
  } catch (err) {
    return err;
  }
};
export const ExportSingleWeekData = async (week) => {
  try {
    const url = `/export/single-week-info?week=${week}`;
    const response: any = axiosConfig(url, "get", {}, "arraybuffer");
    return response;
  } catch (err) {
    return err;
  }
};
