import React, { FC, ReactNode } from "react";
import styles from "./WeekCard.module.css";

interface Props {
  weekNo?: string;
  initialCount?: string;
  actualCount?: string;
  children?: ReactNode;
  className?: string;
  title?: "Week" | "Day";
}

const WeekCard: FC<Props> = ({
  weekNo,
  initialCount,
  actualCount,
  className,
  title,
}: Props) => {
  return (
    <div className={`${styles.weekCard} ${className && className}`}>
      <h2
        className={styles.textStyle}
        data-testid="weekNo"
      >{`${title} ${weekNo}`}</h2>
      <div className={styles.countContainer}>
        <h2
          className={`${styles.initialCount} ${styles.countFontStyles}`}
          data-testid="initialCount"
        >
          {initialCount}
        </h2>
        <div className={styles.horizontalLine}></div>
        <h2
          className={`${styles.actualCount} ${styles.countFontStyles}`}
          data-testid="actualCount"
        >
          {actualCount}
        </h2>
      </div>
    </div>
  );
};
WeekCard.defaultProps = {
  title: "Week",
};
export default WeekCard;
