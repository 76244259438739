import RouteProps from "../interfaces/routeInterface";
import { HomePage } from "../pages/HomePage";
import { LoginPage } from "../pages/LoginPage";
import { SupportPage } from "../pages/Support";
import { MemBerDashBoard } from "../pages/MembersDashBoard";
export const HOME = "/";
export const LOGIN = "/login";
export const MEMBERS_DASHBOARD = "/members";
export const SUPPORT = "/support";

const routes: RouteProps[] = [
  {
    path: HOME,
    name: "Home",
    Component: HomePage,
    isPrivate: true,
  },
  {
    path: LOGIN,
    name: "Login",
    Component: LoginPage,
    isPrivate: false,
  },
  {
    path: SUPPORT,
    name: "Support",
    Component: SupportPage,
    isPrivate: false,
  },
  {
    path: MEMBERS_DASHBOARD,
    name: "Member",
    Component: MemBerDashBoard,
    isPrivate: true,
  },
];
export default routes;
