import { Routes, Route } from "react-router-dom";
import RouteProps from "../interfaces/routeInterface";
import routes from "./constant";
import PrivateRoute from "./PrivateRoute";

const RouterConfig = () => {
  return (
    <Routes>
      {routes.map((rte: RouteProps, key: number) => {
        const { Component } = rte;
        if (rte.isPrivate) {
          return (
            <Route
              key={key}
              path={rte.path}
              element={
                <PrivateRoute>
                  <Component name={rte.name} />
                </PrivateRoute>
              }
            />
          );
        }
        return (
          <Route
            key={key}
            path={rte.path}
            element={<Component name={rte.name} />}
          />
        );
      })}
    </Routes>
  );
};

export default RouterConfig;
