import { ReactElement, FC, ReactNode } from "react";
import styles from "./Button.module.css";
interface Props {
  type: "submit" | "button";
  size: "large" | "medium" | "small";
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
  [propName: string]: any;
}
const Button: FC<Props> = ({
  type,
  className,
  disabled,
  size,
  children,
  ...rest
}: Props) => {
  return (
    <>
      <button
        type={type}
        className={`${styles.btn} ${className && className} ${
          size === "large" && styles.large
        } ${size === "medium" && styles.medium}
        ${size === "small" && styles.small}
        
        `}
        disabled={disabled}
        {...rest}
        data-testid="button"
      >
        {children}
      </button>
    </>
  );
};

export default Button;
