import { getHost } from "../../axiosConfig/axiosConfig";
import { LOCALSTORAGE_KEYS } from "../../constants";
import axios from "axios";
import { axiosConfig } from "../../axiosConfig/axiosConfig";
export interface Response {
  status: "success" | "error";
  data?: any;
}

export const logoutUser = async (): Promise<Response> => {
  try {
    const url = getHost() + "/api/auth/logout";
    const refreshToken = localStorage.getItem(LOCALSTORAGE_KEYS.refreshToken);
    const sessionId = localStorage.getItem(LOCALSTORAGE_KEYS.sessionId);
    const payload = {
      refreshToken: refreshToken,
      sessionId: sessionId,
    };
    await axios.post(url, payload);
    return {
      status: "success",
    };
  } catch (err: any) {
    return {
      status: "error",
    };
  }
};
export const getUserCount = async (): Promise<Response> => {
  try {
    const url = "/users/count";
    const response: any = await axiosConfig(url, "get", {}, "");
    return {
      status: "success",
      data: response.data,
    };
  } catch (err: any) {
    return {
      status: "error",
    };
  }
};
