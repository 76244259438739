import { Alert, Card, Grid, Box, Snackbar, AlertColor } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import ConfirmDialog from "../../components/ConfirmDialog";
import CreateUserDialog from "../../forms/CreateUser";
import FeedBackCard from "../../components/FeedBackCard";
import SelectDropDown from "../../components/SelectDropDown";
import WeekCard from "../../components/WeekCard";
import DashBoardLayout from "../../modules/DashBoardLayout";
import { DropDownAttributes, CreateUserAttributes } from "../../utils";
import styles from "./memberDashboard.module.css";
import {
  createExportReviewDropDown,
  ExportAllReviewByBelief,
  ExportAllReviewByUserId,
  ExportAllUsersReview,
  GetAllBelief,
  GetAllNamesByBelief,
  GetFeedBackData,
  GetUserWeekData,
  DeleteSessionData,
  DeleteMember,
} from "./utils";
import BackDropSpinner from "../../components/BackDropLoader";
import { images } from "../../assets/images";
import fileSaver from "file-saver";
const typeofFile =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const MemBerDashBoard: FC = (props) => {
  const [updateCount, setUpdateCount] = useState<boolean>(false);
  const [beliefs, setBeliefs] = useState<Array<DropDownAttributes> | any>([{}]);
  const [belief, setBelief] = useState({ id: "", name: "" });
  const [namesArray, setNamesArray] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<number>(0);
  const [activeWeekItem, setActiveWeekItem] = useState(0);
  const [memberData, setMemberData] = useState<any>();
  const [feedBackData, setFeedBackData] = useState<any>();
  const [userInfo, setUserInfo] = useState({ userId: "", userName: "" });
  const [openInfoMessage, setOpenInfoMessage] = useState({
    message: "User has no Reviews!",
    severity: "warning" as AlertColor,
    open: false,
  });
  const [dialogInfoMessage, setDialogInfoMessage] = useState({
    message: "",
    title: "",
    open: false,
    mode: "",
  });
  const [dialogCreateUser, setDialogCreateUser] =
    useState<CreateUserAttributes>({
      message: "",
      title: "",
      open: false,
      anchorEl: null,
    });
  const { Smile1Image, Smile2Image, Smile3Image, Smile4Image, Smile5Image } =
    images;
  const imagesArray = [
    Smile1Image,
    Smile2Image,
    Smile3Image,
    Smile4Image,
    Smile5Image,
  ];
  const [activeWeekId, setActiveWeekId] = useState<string>("");
  const [exportType, setExportType] = useState("all");
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenInfoMessage({
      message: "",
      severity: "warning",
      open: false,
    });
  };
  const getInitialData = async (isInit?: boolean) => {
    setIsLoading(true);
    const beliefsData: any = await GetAllBelief();
    setBeliefs(beliefsData);
    if (isInit) setBelief({ name: beliefsData[0].name, id: beliefsData[0].id });
    const userNames = await GetAllNamesByBelief(belief.id || beliefsData[0].id);
    setNamesArray(userNames);
    if (userNames instanceof Array && userNames.length > 0) {
      const memberData: any = await GetUserWeekData(userNames[0].id);
      setActiveWeekId(memberData?.data.info[0]?.weekid);
      setMemberData(memberData?.data);
      setUserInfo({ userId: userNames[0].id, userName: userNames[0].username });
      const reviews = await GetFeedBackData(
        userNames[0].id,
        memberData?.data.info[0]?.weekid
      );
      setFeedBackData(reviews.data);
    }
    setIsLoading(false);
    setActiveWeekItem(0);
    setActiveItem(0);
  };
  const openDelSessionDialog = () => {
    setDialogInfoMessage({
      title: "Are you sure?",
      message:
        "Are you sure you want to delete this members activity data? Once deleted its irreversible to get the data",
      open: true,
      mode: "Session",
    });
  };
  const openDelMemberDialog = () => {
    setDialogInfoMessage({
      title: "Are you sure?",
      message:
        "Are you sure you want to delete this member? Once deleted its irreversible to get the user and their activity",
      open: true,
      mode: "User",
    });
  };
  const openCreateUserDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDialogCreateUser({
      title: "Are you sure?",
      message:
        "Are you sure you want to delete this member? Once deleted its irreversible to get the user and their activity",
      open: true,
      anchorEl: event.currentTarget,
    });
  };
  const handleDialogClose = () => {
    setDialogInfoMessage({
      title: "",
      message: "",
      open: false,
      mode: "",
    });
  };
  const handleCreateUserClose = () => {
    setDialogCreateUser({
      title: "",
      message: "",
      open: false,
      anchorEl: null,
    });
  };
  const handleCreateUserSave = async () => {
    try {
      setIsLoading(true);
      await getInitialData();
      setOpenInfoMessage({
        message: "Member created successfully",
        severity: "success" as AlertColor,
        open: true,
      });
      setUpdateCount(!updateCount);
    } catch (err: any) {
      setOpenInfoMessage({
        message: "Error while reloading data",
        severity: "error" as AlertColor,
        open: true,
      });
      return err;
    } finally {
      setIsLoading(false);
    }
  };
  const DelSessionData = async () => {
    try {
      setIsLoading(true);
      await DeleteSessionData(userInfo.userId);
      setOpenInfoMessage({
        message: "Successfully deleted the session data",
        severity: "success" as AlertColor,
        open: true,
      });
      await getInitialData();
      setUpdateCount(!updateCount);
    } catch (err: any) {
      setOpenInfoMessage({
        message: "Error while deleting the session data",
        severity: "error" as AlertColor,
        open: true,
      });
      return err;
    } finally {
      setIsLoading(false);
    }
  };
  const DelMember = async () => {
    try {
      setIsLoading(true);
      await DeleteSessionData(userInfo.userId);
      await DeleteMember(userInfo.userId);
      setOpenInfoMessage({
        message: "Successfully deleted the member and their activity",
        severity: "success" as AlertColor,
        open: true,
      });
      await getInitialData();
    } catch (err: any) {
      setOpenInfoMessage({
        message: "Error while deleting the member",
        severity: "error" as AlertColor,
        open: true,
      });
      return err;
    } finally {
      setIsLoading(false);
    }
  };
  const createBeliefsDropDown = () => {
    const options: Array<DropDownAttributes> = [{}];
    for (const type of beliefs) {
      options.push({ id: type.id, description: type.name });
    }
    return options;
  };
  const handleChange = async (e: Event) => {
    setNamesArray(undefined);
    setMemberData(undefined);
    setFeedBackData(undefined);
    setActiveItem(0);
    setActiveWeekItem(0);
    setActiveWeekId("");
    const selectElement = e.target as HTMLSelectElement;
    const selectedIndex = selectElement.selectedIndex;
    const selectedOption = selectElement.options[selectedIndex];
    const name = selectedOption.text;
    const id = selectedOption.value;
    setBelief({ name, id });
    setIsLoading(true);
    const data: any = await GetAllNamesByBelief(id);
    setNamesArray(data);
    if (data instanceof Array && data.length > 0) {
      const memberData: any = await GetUserWeekData(data[0].id);
      setMemberData(memberData?.data);
      setUserInfo({ userId: data[0].id, userName: data[0].username });
      setActiveWeekId(memberData?.data.info[0]?.weekid);
      const reviews = await GetFeedBackData(
        data[0].id,
        memberData?.data.info[0]?.weekid
      );
      setFeedBackData(reviews.data);
    }
    setIsLoading(false);
  };

  const handleListItems = async (id, username, index) => {
    setMemberData(undefined);
    setFeedBackData(undefined);
    setIsLoading(true);
    setActiveWeekItem(0);
    setActiveItem(index);
    setActiveWeekId("");
    setUserInfo({ userId: id, userName: username });
    const data: any = await GetUserWeekData(id);
    setMemberData(data.data);
    setActiveWeekId(data?.data.info[0]?.weekid);
    const reviews = await GetFeedBackData(id, data?.data.info[0]?.weekid);
    setFeedBackData(reviews.data);
    setIsLoading(false);
  };
  const handleWeekCardBox = async (id, week, index) => {
    setFeedBackData(undefined);
    setIsLoading(true);
    setActiveWeekItem(index);
    setActiveWeekId(week);
    const reviews = await GetFeedBackData(id, week);
    setFeedBackData(reviews?.data);
    setIsLoading(false);
  };
  const exportAllReviewsByBelief = async () => {
    try {
      setIsLoading(true);
      const response: any = await ExportAllReviewByBelief(
        belief.id,
        "arraybuffer"
      );
      if (response?.response?.status === 400) {
        setOpenInfoMessage({
          message: " Currently there are no Reviews!",
          severity: "warning",
          open: true,
        });
      } else {
        const blob = new Blob([response], {
          type: typeofFile,
        });
        fileSaver.saveAs(blob, `${belief.name.toUpperCase()}_Reviews.xlsx`);
      }
    } catch (err: any) {
      return err;
    } finally {
      setIsLoading(false);
    }
  };
  const exportAllReviewsByUserId = async () => {
    try {
      setIsLoading(true);
      const response: any = await ExportAllReviewByUserId(
        userInfo.userId,
        "arraybuffer"
      );
      console.log(response);
      if (response?.response?.status === 400) {
        setOpenInfoMessage({
          message: " Currently there are no Reviews!",
          severity: "warning",
          open: true,
        });
      } else {
        const blob = new Blob([response], {
          type: typeofFile,
        });
        fileSaver.saveAs(blob, `${userInfo.userName}_Reviews.xlsx`);
      }
    } catch (err: any) {
      return err;
    } finally {
      setIsLoading(false);
    }
  };
  const exportAllUsersReviews = async () => {
    try {
      setIsLoading(true);
      const response: any = await ExportAllUsersReview("arraybuffer");
      console.log(response);
      if (response?.response?.status === 400) {
        setOpenInfoMessage({
          message: " Currently there are no Reviews!",
          severity: "warning",
          open: true,
        });
      } else {
        const blob = new Blob([response], {
          type: typeofFile,
        });
        fileSaver.saveAs(blob, `Reviews.xlsx`);
      }
    } catch (err: any) {
      return err;
    } finally {
      setIsLoading(false);
    }
  };
  const handleExportReviews = (e: Event) => {
    const selectElement = e.target as HTMLSelectElement;
    const selectedIndex = selectElement.selectedIndex;
    const selectedOption = selectElement.options[selectedIndex];
    setExportType(selectedOption.value);
  };
  const errorCreatingUser = (msg?: string) => {
    setOpenInfoMessage({
      message: msg || "Error while creating the member",
      severity: "error" as AlertColor,
      open: true,
    });
  };
  useEffect(() => {
    getInitialData(true);
  }, []);
  return (
    <div>
      <DashBoardLayout
        sidebar
        updateCount={updateCount}
        exportButton={
          <div style={{ display: "flex" }}>
            <div style={{ width: "210px" }}>
              <SelectDropDown
                titleExistOnChange={false}
                title={"All"}
                options={createExportReviewDropDown}
                onChange={handleExportReviews}
              />
            </div>
            <Button
              type="button"
              size="small"
              style={{ marginRight: "12px" }}
              onClick={() =>
                exportType === "belief"
                  ? exportAllReviewsByBelief()
                  : exportAllUsersReviews()
              }
              data-testid="ExportAllWeeksData"
            >
              Export
            </Button>
            <Button
              className={styles.actionbutton}
              type="button"
              size="small"
              onClick={openCreateUserDialog}
            >
              Create Member
            </Button>
          </div>
        }
      >
        <Grid item xs={10}>
          <Box>
            <Card>
              <Grid container>
                <Grid item xs={3}>
                  <div style={{ margin: "20px 0" }}>
                    <SelectDropDown
                      titleExistOnChange={false}
                      title={"Christian"}
                      options={beliefs && createBeliefsDropDown()}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={styles.scrollbar}>
                    {namesArray &&
                      (namesArray.length > 0 ? (
                        <>
                          <ul>
                            {namesArray.map((value, index) => (
                              <li
                                className={`${styles.listItems} ${
                                  activeItem === index && styles.activeLink
                                }`}
                                onClick={() =>
                                  handleListItems(
                                    value.id,
                                    value.username,
                                    index
                                  )
                                }
                                data-testid="username"
                                key={value.id}
                              >
                                {value.username}
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <>
                          <div className={styles.users}>No Users Found</div>
                        </>
                      ))}
                  </div>
                </Grid>
                <Grid item xs={9} sx={{ backgroundColor: "#E5F2FF" }}>
                  {memberData && (
                    <div>
                      <div className={styles.header}>
                        <div style={{ display: "flex" }}>
                          <h1 className={styles.name}>{memberData.username}</h1>
                          <Button
                            type="button"
                            size="large"
                            onClick={exportAllReviewsByUserId}
                          >
                            Export Selected User`s Reviews
                          </Button>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Button
                            className={styles.actionbutton}
                            type="button"
                            size="small"
                            onClick={openDelMemberDialog}
                          >
                            Delete Member
                          </Button>
                          <Button
                            className={styles.actionbutton}
                            type="button"
                            size="small"
                            onClick={openDelSessionDialog}
                          >
                            Delete Activity Data
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={`${styles.weekCardContainer}`}>
                    {memberData &&
                      memberData.info.map((val, index) => (
                        <React.Fragment key={index}>
                          <div
                            className={`${
                              activeWeekItem === index && styles.weekCardBox
                            }`}
                            onClick={() =>
                              handleWeekCardBox(
                                memberData.userId,
                                val.weekid,
                                index
                              )
                            }
                            data-testid="weekCard"
                          >
                            <WeekCard
                              initialCount="7"
                              actualCount={val.completed}
                              weekNo={val.week}
                              className={styles.weekCardStyles}
                            />
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                  <div
                    className={`${styles.feedbackContainer} ${styles.scrollbar}`}
                  >
                    {feedBackData && (
                      <>
                        <h1 className={styles.feedbackTitle}>
                          {`${feedBackData.username} Comments for week ${feedBackData.week}`}
                        </h1>
                        {feedBackData.reviews &&
                        Array.isArray(feedBackData.reviews) &&
                        feedBackData.reviews.length > 0 ? (
                          feedBackData.reviews.map((rev, index) => (
                            <FeedBackCard
                              dayNumber={rev.day}
                              description={rev.comment}
                              beforeImage={
                                rev.beforeMeditation !== 0
                                  ? imagesArray[rev.beforeMeditation - 1]
                                  : undefined
                              }
                              afterImage={
                                rev.afterMeditation !== 0
                                  ? imagesArray[rev.afterMeditation - 1]
                                  : undefined
                              }
                              key={index}
                            />
                          ))
                        ) : (
                          <div className={styles.comments}>No Comments</div>
                        )}
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Grid>
      </DashBoardLayout>
      <BackDropSpinner openBackDropLoader={isLoading} />
      <Snackbar
        open={openInfoMessage.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={openInfoMessage.severity}
          sx={{ width: "100%", fontSize: "15px" }}
          onClose={handleClose}
        >
          {openInfoMessage.message}
        </Alert>
      </Snackbar>
      <ConfirmDialog
        open={dialogInfoMessage.open}
        title={dialogInfoMessage.title}
        onAccept={
          dialogInfoMessage.mode == "Session" ? DelSessionData : DelMember
        }
        onReject={handleDialogClose}
      >
        {dialogInfoMessage.message}
      </ConfirmDialog>
      <CreateUserDialog
        open={dialogCreateUser.open}
        title={dialogCreateUser.title}
        onAccept={handleCreateUserSave}
        onReject={handleCreateUserClose}
        anchorEl={dialogCreateUser.anchorEl}
        beliefs={beliefs}
        errorCreatingUser={errorCreatingUser}
      >
        {dialogCreateUser.message}
      </CreateUserDialog>
    </div>
  );
};
