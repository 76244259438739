import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { FC } from "react";
interface Props {
  openBackDropLoader: boolean;
}
const BackDropSpinner: FC<Props> = ({ openBackDropLoader }: Props) => {
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDropLoader}
      >
        <CircularProgress sx={{ color: "#ffffff" }} size={"5rem"} />
      </Backdrop>
    </div>
  );
};
export default BackDropSpinner;
