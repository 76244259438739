import { axiosConfig } from "../../axiosConfig/axiosConfig";

export const GetAllBelief = async () => {
  try {
    const url = "/beliefs";

    const { data }: any = await axiosConfig(url, "get", {}, "");
    return data;
  } catch (err: any) {
    return err;
  }
};
export const GetAllNamesByBelief = async (id: any) => {
  try {
    const url = `/users/${id}`;
    const { data }: any = await axiosConfig(url, "get", {}, "");
    return data;
  } catch (err: any) {
    return err;
  }
};
export const GetUserWeekData = async (id: any) => {
  try {
    const url = `/stats/user-info/${id}`;
    const data = await axiosConfig(url, "get", {}, "");
    return data;
  } catch (err: any) {
    return err;
  }
};

export const GetFeedBackData = async (id: any, week: string) => {
  try {
    const url = `/reviews/${id}?week=${week}`;
    const data = await axiosConfig(url, "get", {}, "");
    return data;
  } catch (err: any) {
    return err;
  }
};

export const ExportAllReviewByBelief = async (beliefId, responseType) => {
  try {
    const url = `/export/review/belief?beliefId=${beliefId}`;
    const response = await axiosConfig(url, "get", {}, responseType);
    return response;
  } catch (err) {
    return err;
  }
};
export const ExportAllReviewByUserId = async (userId, responseType) => {
  try {
    const url = `/export/all-reviews/${userId}`;
    const response = await axiosConfig(url, "get", {}, responseType);
    return response;
  } catch (err) {
    return err;
  }
};

export const ExportAllUsersReview = async (responseType) => {
  try {
    const url = `/export/review/all`;
    const response = await axiosConfig(url, "get", {}, responseType);
    return response;
  } catch (err) {
    return err;
  }
};

export const DeleteSessionData = async (userId) => {
  try {
    const url = `/sessions/delete-sessions/${userId}`;
    const response = await axiosConfig(url, "delete", {}, "");
    return response;
  } catch (err) {
    return err;
  }
};

export const DeleteMember = async (userId) => {
  try {
    const url = `/auth/delete-user/${userId}`;
    const response = await axiosConfig(url, "delete", {}, "");
    return response;
  } catch (err) {
    return err;
  }
};
export const createExportReviewDropDown = [
  {
    id: "all",
    description: "All",
  },
  {
    id: "belief",
    description: "Belief",
  },
];
