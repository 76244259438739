import { FC, ReactElement } from "react";
import styles from "./Support.module.css";
import { images } from "../../assets/images";

export const SupportPage: FC = (): ReactElement => {
  const { heartImage } = images;
  return (
    <>
      <div className={styles.aboutSection}>
        <div className={styles.header}>
          <img src={heartImage} style={{ height: "120px" }} />
        </div>
        <div style={{ padding: "50px" }}>
          <h2>About Us</h2>
          <p>
            Religious and wisdom traditions throughout history have drawn on the
            heart as an image for focusing the mind and experiencing the sacred.
            Our app features carefully curated programs of Christian, Islamic
            and Mindfulness practices to bring the benefits of heart centered
            meditation to contemporary people of various faiths and none.
          </p>
          <br />
          <h2>Support</h2>
          <p>
            If you have any queries, suggestion, or operational/ technical
            issues, please reach out to us at&nbsp;
            <a
              href="mailto:meditation-app-support@launchventures.co"
              style={{ color: "#184373" }}
            >
              meditation-app-support@launchventures.co
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
