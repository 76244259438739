import React, { FC, useEffect, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import DashBoardLayout from "../../modules/DashBoardLayout";
import styles from "./HomePage.module.css";
import ReligionCard from "../../components/ReligionCard";
import WeekCard from "../../components/WeekCard";
import SelectDropDown from "../../components/SelectDropDown";
import {
  GetAllMeditationData,
  GetMeditationDataByWeek,
  options,
  ExportAllWeekData,
  ExportSingleWeekData,
  IMeditationData,
  IResponse,
  GetAllWeeks,
} from "./utils";
import CircularProgressBar from "../../components/CircularLoader";
import { images } from "../../assets/images";
import Button from "../../components/Button";
import fileSaver from "file-saver";
import { DropDownAttributes } from "../../utils";
import BackDropSpinner from "../../components/BackDropLoader";
const typeofFile =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const HomePage = () => {
  const [meditationData, setMeditationData] = useState<
    IMeditationData | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [weekNumber, setWeekNumber] = useState<string>("");
  const [week, setWeek] = useState<Array<DropDownAttributes> | any>([{}]);
  const getAllWeeks = async () => {
    setIsLoading(true);
    const response = await GetAllWeeks();
    setWeek(response);
  };
  const getMeditationData = async () => {
    setIsLoading(true);
    const response: IResponse = await GetAllMeditationData();
    if (response.status === "success" && response.data) {
      const { data } = response;
      setMeditationData({
        ...meditationData,
        mindfulness: data[0],
        christian: data[1],
        islam: data[2],
      });
    }
    setIsLoading(false);
  };

  const onChange = async (e: Event) => {
    setMeditationData(undefined);
    const weekNo: string = (e.target as HTMLSelectElement).value;
    setWeekNumber(weekNo);
    if (weekNo === "") {
      getMeditationData();
      return;
    }
    setIsLoading(true);
    const response: IResponse = await GetMeditationDataByWeek(weekNo);
    if (response.status === "success" && response.data) {
      const { data } = response;
      setMeditationData({
        ...meditationData,
        mindfulness: data[0],
        christian: data[1],
        islam: data[2],
      });
    }
    setIsLoading(false);
  };
  const renderCircularProgress = () => {
    return (
      <>
        {isLoading && (
          <div style={{ marginLeft: "30px" }} data-testid="loader">
            <CircularProgressBar />
          </div>
        )}
      </>
    );
  };
  const exportFile = async () => {
    try {
      setIsDownloading(true);
      if (weekNumber === "") {
        const response: any = await ExportAllWeekData();
        const blob = new Blob([response], {
          type: typeofFile,
        });
        fileSaver.saveAs(blob, "WeekData.xlsx");
      } else if (weekNumber !== "") {
        const response: any = await ExportSingleWeekData(weekNumber);
        const blob = new Blob([response], {
          type: typeofFile,
        });
        fileSaver.saveAs(blob, `week${weekNumber}.xlsx`);
      }
    } catch (err: any) {
      return err;
    } finally {
      setIsDownloading(false);
    }
  };
  const createWeekDropDown = () => {
    const options: Array<DropDownAttributes> = [{}];
    for (const type of week) {
      options.push({ id: type.value, description: "Week " + type.value });
    }
    return options;
  };
  useEffect(() => {
    getAllWeeks();
    getMeditationData();
  }, []);
  return (
    <div>
      <DashBoardLayout
        sidebar
        exportButton={
          <Button type="button" size="large" onClick={exportFile}>
            Export Selected Week(s) data
          </Button>
        }
      >
        <Grid item md={9}>
          <Box>
            <Paper>
              <div style={{ height: "110px" }}>
                <div className={styles.textWrapper}>
                  <img
                    src={images.filterImage}
                    alt="filter"
                    style={{ height: "37px", width: "40px" }}
                  />
                  <h2 className={styles.text}>Filter By</h2>
                  <div className={styles.dropdown}>
                    <SelectDropDown
                      options={createWeekDropDown()}
                      title={"All Weeks"}
                      titleExistOnChange={true}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <Box>
                  <Paper sx={{ backgroundColor: "#E5F2FF" }}>
                    <div className={styles.container}>
                      <div className={styles.wrapper}>
                        <ReligionCard image={images.mindfulImage}>
                          {meditationData &&
                            meditationData?.mindfulness?.info?.map(
                              (res: any, index: number) => (
                                <div key={index} data-testid="mindfulWeekCard">
                                  <WeekCard
                                    title={res.week ? "Week" : "Day"}
                                    initialCount={
                                      meditationData.mindfulness.totalUsers
                                    }
                                    actualCount={res.completed}
                                    weekNo={res.week || res.day}
                                  />
                                </div>
                              )
                            )}
                          {renderCircularProgress()}
                        </ReligionCard>
                        <ReligionCard image={images.islamImage}>
                          {meditationData &&
                            meditationData?.islam?.info?.map(
                              (res: any, index: number) => (
                                <div key={index} data-testid="islamWeekCard">
                                  <WeekCard
                                    title={res.week ? "Week" : "Day"}
                                    initialCount={
                                      meditationData.islam.totalUsers
                                    }
                                    actualCount={res.completed}
                                    weekNo={res.week || res.day}
                                  />
                                </div>
                              )
                            )}
                          {renderCircularProgress()}
                        </ReligionCard>
                        <ReligionCard image={images.christianImage}>
                          {meditationData &&
                            meditationData?.christian?.info?.map(
                              (res: any, index: number) => (
                                <div
                                  key={index}
                                  data-testid="christianWeekCard"
                                >
                                  <WeekCard
                                    title={res.week ? "Week" : "Day"}
                                    initialCount={
                                      meditationData.christian.totalUsers
                                    }
                                    actualCount={res.completed}
                                    weekNo={res.week || res.day}
                                  />
                                </div>
                              )
                            )}
                          {renderCircularProgress()}
                        </ReligionCard>
                      </div>
                    </div>
                  </Paper>
                </Box>
              </div>
            </Paper>
          </Box>
        </Grid>
      </DashBoardLayout>
      <BackDropSpinner openBackDropLoader={isDownloading} />
    </div>
  );
};
