import React, { FC, ReactElement, useState } from "react";
import styles from "./Login.module.css";
import { Formik, Form } from "formik";
import Button from "../../components/Button";
import InputTextField from "../../modules/InputTextField";
import { initialValues, Login, LoginResponse, LoginSchema } from "./utils";
import { useAuth } from "../../navigation/ProvideAuth";
import { LOCALSTORAGE_KEYS } from "../../constants";
import { useNavigate } from "react-router-dom";
import { HOME } from "../../navigation/constant";
import AlertText from "../../components/AlertText";
import { images } from "../../assets/images";

export const LoginPage: FC = (): ReactElement => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [loginResponse, setLoginResponse] = useState<
    LoginResponse | undefined
  >();
  return (
    <>
      <div>
        <div className={styles.header}></div>

        <div>
          <div className={styles.container}>
            <div className={styles.logoContainer}>
              <img src={images.heartImage} alt="Logo" />
            </div>
            <div>
              <Formik
                validateOnChange={true}
                initialValues={initialValues}
                validationSchema={LoginSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  const response = await Login({ ...values });
                  if (response.status === "success" && response.data) {
                    localStorage.setItem(
                      LOCALSTORAGE_KEYS.authToken,
                      response.data.accessToken
                    );
                    localStorage.setItem(
                      LOCALSTORAGE_KEYS.refreshToken,
                      response.data.refreshToken
                    );
                    localStorage.setItem(
                      LOCALSTORAGE_KEYS.sessionId,
                      response.data.sessionId
                    );
                    setAuth(response.data.accessToken);
                    navigate(HOME, { replace: true });
                  }
                  setLoginResponse(response);
                  setSubmitting(false);
                }}
              >
                {({ errors, touched, handleChange, isSubmitting }) => (
                  <Form>
                    <div className={styles.inputContainer}>
                      <InputTextField
                        name="userName"
                        type="text"
                        placeHolder="Username"
                        className={styles.inputPadding}
                        error={
                          errors.userName && touched.userName ? true : false
                        }
                        onChange={handleChange}
                      />

                      <InputTextField
                        name="password"
                        type="password"
                        placeHolder="********"
                        className={styles.inputPadding}
                        error={
                          errors.password && touched.password ? true : false
                        }
                        onChange={handleChange}
                        data-testid="password"
                      />
                    </div>
                    <div className={styles.buttonContainer}>
                      <Button
                        type="submit"
                        size="medium"
                        disabled={isSubmitting}
                      >
                        Login
                      </Button>
                    </div>
                    {loginResponse?.status == "error" && (
                      <div style={{ marginTop: "20px" }}>
                        <AlertText
                          type={loginResponse.status}
                          text={loginResponse.message}
                        />
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
