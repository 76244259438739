import React, { FC, ReactNode } from "react";
import styles from "./ReligionCard.module.css";
interface Props {
  image?: string;
  children?: ReactNode;
}

const ReligionCard: FC<Props> = ({ children, image }: Props) => {
  return (
    <div className={styles.dayCardContainer}>
      <img
        src={image}
        alt="image"
        style={{ maxHeight: "100px", minWidth: "105px", objectFit: "contain" }}
      />
      {children}
    </div>
  );
};

export default ReligionCard;
