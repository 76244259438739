import { ReactElement, FC, ReactNode, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import styles from "./ConfirmDialog.module.css";
import Button from "../Button";
interface Props {
  open: boolean;
  title?: string;
  children?: ReactNode;
  onAccept(): void;
  onReject(): void;
  [propName: string]: any;
}
const ConfirmDialog: FC<Props> = ({
  open,
  title,
  children,
  onAccept,
  onReject,
  ...rest
}: Props) => {
  const handleDialogClose = () => {
    onReject();
  };
  const handleSuccessDialog = () => {
    onAccept();
    handleDialogClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle variant="h4">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="h5">{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="button" size="small" onClick={handleSuccessDialog}>
          Yes
        </Button>
        <Button type="button" size="small" onClick={handleDialogClose}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
