import React, { FunctionComponent, ReactElement } from "react";
import styles from "./AlertText.module.css";
import { createUseStyles } from "react-jss";
interface Props {
  type: "error" | "success";
  text: string;
  className?: string;
}
const useAlertTextStyles = createUseStyles({
  text: {
    fontSize: "1.2rem",
    fontFamily: "FreightSansProBook-Regular",
    fontWeight: 400,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  success: {
    color: "#00ab2e",
  },
  error: {
    color: " #ea4335",
  },
  icon: {
    paddingRight: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
const AlertText: FunctionComponent<Props> = ({
  type,
  text,
  className,
}): ReactElement => {
  const classes = useAlertTextStyles();
  return (
    <div
      className={`${classes.text} ${type === "error" && classes.error} ${
        type === "success" && classes.success
      } ${className && className}`}
      data-testid="message"
    >
      {text}
    </div>
  );
};

export default AlertText;
