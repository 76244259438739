import { Box, CircularProgress } from "@mui/material";
import React, { FC } from "react";

interface IProps {
  size?: string;
  color?: any;
}

const CircularProgressBar: FC<IProps> = ({ size, color }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress size={size} color={color} />
    </Box>
  );
};
CircularProgressBar.defaultProps = {
  size: "4.4rem",
};
export default CircularProgressBar;
