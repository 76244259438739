import { Card, Grid } from "@mui/material";
import React, { FC, ReactNode } from "react";
import styles from "./FeedBack.module.css";
interface Props {
  dayNumber: string;
  description: string;
  beforeImage?: string;
  afterImage?: string;
}

const FeedBackCard: FC<Props> = ({
  dayNumber,
  description,
  afterImage,
  beforeImage,
}) => {
  return (
    <div>
      <Card sx={{ boxSizing: "border-box", height: "100px", width: "520px" }}>
        <Grid container>
          <Grid item xs={7}>
            <h1 className={styles.title} data-testid="dayNumber">
              Day {dayNumber} comments
            </h1>
            <p className={styles.description} data-testid="description">
              {description}
            </p>
          </Grid>
          <Grid item xs={5}>
            <div className={styles.flex_center}>
              <div
                className={`${styles.before_meditation} ${styles.flex_column}`}
              >
                <h5 className={styles.reviewTitle}>Before</h5>
                {beforeImage && (
                  <img
                    src={beforeImage}
                    alt="smileyFace"
                    className={styles.emojis}
                    data-testid="beforeImage"
                  />
                )}
              </div>
              <div
                className={`${styles.after_meditation} ${styles.flex_column}`}
              >
                <h5 className={styles.reviewTitle}>After</h5>
                {afterImage && (
                  <img
                    src={afterImage}
                    alt="smileyFace"
                    className={styles.emojis}
                    data-testid="afterImage"
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default FeedBackCard;
