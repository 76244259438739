import axios from "axios";
import { NODE_ENV } from "../config";
import { LOCALSTORAGE_KEYS } from "../constants";
import { ValidateRefreshToken } from "./utils";
export const getHost = () => {
  const loc = window.location;
  const prot = loc.protocol;
  const mode = NODE_ENV || "development";
  console.log(NODE_ENV);
  //return "https://dev-cma.lv.launchventures.co";
  return prot + "//" + (mode === "production" ? loc.host : "localhost:4000");
  // return 'https://platform.eagleconsulting.co.in';
  //return 'https://ec2-3-110-97-113.ap-south-1.compute.amazonaws.com';
};
console.log(getHost());
const axiosInstance = axios.create({
  baseURL: `${getHost()}/api`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(function (config: any) {
  const token =
    "Bearer" + " " + window.localStorage.getItem(LOCALSTORAGE_KEYS.authToken);
  config.headers.Authorization = token;
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      (error.response.data.message === "Expired token" ||
        Object.keys(error.response.data).length === 0)
    ) {
      originalRequest._retry = true;
      const response = await ValidateRefreshToken();
      if (response.status === "success") {
        const { accessToken, refreshToken, sessionId } = response.data;
        localStorage.setItem(LOCALSTORAGE_KEYS.authToken, accessToken);
        localStorage.setItem(LOCALSTORAGE_KEYS.refreshToken, refreshToken);
        localStorage.setItem(LOCALSTORAGE_KEYS.sessionId, sessionId);
        axios.defaults.headers.common["Authorization"] =
          "Bearer" + " " + accessToken;
        return axiosInstance(originalRequest);
      } else {
        window.location.href = "/login";
        return;
      }
    }
    if (error.response.status >= 500) {
      window.location.href = "/login";
      return;
    }

    return Promise.reject(error);
  }
);

export const axiosConfig = async (url, method, body, responseType) => {
  const options = {
    method: method || "GET",
    url: url,
    data: body,
    responseType: responseType,
  };
  const res = await axiosInstance(options);
  return res;
};
